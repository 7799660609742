import React from 'react';
import './about.css';
const About = () => (
	<div className="wrap">
		<div className="about">
			<div className="about-intro">
				<h4>About</h4>
				<h1>Let me introduce myself</h1>
				<div classNameName="intro-details">
					<div className="intro-image">
						<div className="image">
							<img src="" alt="" />
						</div>
					</div>
					<div className="intro-paragraph padding"><p>As a UI/UX Designer, I focus on designing interfaces that are visually appealing, user-friendly, and intuitive. I conduct user research to understand user needs and create wireframes, prototypes, and high-fidelity designs that illustrate the user journey.
						<br /><br />	Also as a Frontend Developer, I work to bring the UI/UX designs to life using my knowledge of HTML, CSS, JavaScript, and various frameworks and tools. I create responsive and optimized user interfaces that match the design specifications and work across different devices and browsers.<br /> <br />

						Together, I work to create digital products that are not only visually appealing but are also user-friendly, intuitive, and provide the best possible user experience. We communicate closely, provide feedback to each other, and work collaboratively to ensure that the final product is successful in meeting user needs and achieving business goals.</p></div>
				</div>
			</div>
			<div className="about-info">
				<div className="profile padding">
					<h2>Profile</h2>
					<p>UI/UX Designer<br /> Graphic Designer <br /> Front-End Developer <br /> Webdesign & Appdesign</p>
					<h3>Fullname:</h3>
					<p>Vishesh Patel</p>
					<h3>Job:</h3>
					<p>Student</p>
					<h3>Education:</h3>
					<p>2020-2024  <span className='bold'> B-Tech (Cseaiml)</span> Oriental Institute of Science and Technology ,Bhopal
						Madhya Pradesh
					</p>
					<h3>Location:</h3>
					<p>Bhopal(Madhya Pradesh)</p>
				</div>
				<div className="skills padding">
					<h2>Skills</h2>
					<p>Crafting engaging digital experiences with user-centered design and front-end development expertise.</p>
					<ul className="skill-bars">
						<li>
							<div className="progress percent90"><span>90%</span></div>
							<strong>App Design</strong>
						</li>
						<li>
							<div className="progress percent85"><span>85%</span></div>
							<strong>Webdesign</strong>
						</li>
						<li>
							<div className="progress percent80"><span>80%</span></div>
							<strong>Front-End Development</strong>
						</li>
						<li>
							<div className="progress percent95"><span>95%</span></div>
							<strong>UX Research</strong>
						</li>
						<li>

							<div className="progress percent75"><span>75%</span></div>
							<strong>Graphic Design</strong>
						</li>

					</ul>


				</div>
			</div>
			<div className="about-buttons padding">
				<div className="hire-me">
					<button className="about-button">Hire me</button>
				</div>
				<div className="download-cv">
				<a href='Vishesh PAtel.pdf' download='Vishesh PAtel.pdf'><button className="about-button">Download CV</button></a>
				</div>
			</div>
		</div>
	</div>
);

export default About;