import React from 'react';
import './header.css';
import ai from '../../Assests/ai.png';
import { RxArrowTopRight } from 'react-icons/rx';
import { BsLinkedin } from 'react-icons/bs';
import { ImBehance2 } from 'react-icons/im';
import { GrInstagram } from 'react-icons/gr';




const Header = () => (
    <div className="vishesh__header section__padding" id="home">
        <div className="vishesh__header-content">
            <div className='Vishesh__rectangle'>
                <h3>Hey, there!</h3>
            </div>
            <h1 className="gradient__text">I am Vishesh Patel, <span className='Vishesh__designation'> Designer & Developer </span></h1>
            <p>Welcome to my portfolio website  I am a front-end web developer and UI/UX designer with a passion for creating beautiful, functional, and user-friendly websites,
                I’ve worked on a diverse set of projects, working from research to production.
            </p>
            <div className="vishesh__header-content__input">
               
              
                <p><a href="https://www.behance.net/visheshpatel6"  target="_blank">Portfolio <RxArrowTopRight/></a></p>
            </div>
            <div className='Vishesh__circle'>
                <a href=' https://www.linkedin.com/in/vishesh-patel-05278822a/' target="_blank"><BsLinkedin/></a>
                <a href=' https://www.behance.net/visheshpatel6' target="_blank"><ImBehance2/></a>
                <a href=' https://www.instagram.com/vishesh_gurjar_/' target="_blank"><GrInstagram/></a>
           
            </div>
        </div>

        <div className="vishesh__header-image">
            <img src={ai} alt="" />
        </div>
    </div>
);

export default Header;