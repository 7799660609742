import React from 'react';
import './contact.css';
import { GoLocation } from 'react-icons/go';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';




const Contact = () => (
	
	<div class="contact container">
	<form>
		<div class="form">
			<div class="form-txt">
				<h1>Contact Us</h1>
				<span>Let's work togetnpmher to bring your vision to life. Contact me today!</span>
				<div className='details'>
				<h3><a className='icon'><GoLocation/></a>LOCATION</h3>
				<p>Vastu vihar awadhpuri, Bhopal (MP)</p>
				</div>
				<div className='details'>
				<h3><a className='icon'><FaPhoneAlt/></a>PHONE</h3>
				<p>9993036048</p>
				</div>
				<div className='details'>
				<h3><a className='icon'><MdOutlineEmail/></a>EMAIL</h3>
				<p>patelvishesh089@gmail.com</p>
				</div>
			
			</div>
			<div class="form-details">
				<input type="text" name="name" id="name" placeholder="Name" required/>
				<input type="email" name="email" id="email" placeholder="Email" required/>
				<textarea name="message" id="message" cols="52" rows="7" placeholder="Message" required></textarea>
				<button>SEND MESSAGE</button>
			</div>
		</div>
	</form>
</div>


);

export default Contact;