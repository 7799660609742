import React, { useState } from "react";
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { Link } from "react-router-dom";
import logo from '../../Assests/logo.png';
import "./navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (

    <div className="vishesh__navbar">
      <div className="vishesh__navbar-links">
        <div className="vishesh__navbar-links_logo">
          <img src={logo} alt="" />
        </div>
        <div className="vishesh__navbar-links_content">
          <p><Link to="/">About</Link></p>
          <p><Link to="/Port">Portfolio</Link></p>
          <p><Link to="/Exp">Experience</Link></p>
          <p><a href="https://www.behance.net/visheshpatel6" target="_blank">
            Behance
          </a></p>
          <p><a href="https://www.linkedin.com/in/vishesh-patel-05278822a/" target="_blank">
            Linkedin
          </a></p>
          <p><Link to="/Con">Contact</Link></p>

        </div>
      </div>

      <div className="vishesh__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="vishesh__navbar-menu_content scale-up-center">
            <div className="vishesh__navbar-menu_content-links">
              <p><Link to="/">About</Link></p>
              <p><Link to="/Port">Portfolio</Link></p>
              <p><Link to="/Exp">Experience</Link></p>
              <p><a href="https://www.behance.net/visheshpatel6" target="_blank">
                Behance
              </a></p>
              <p><a href="https://www.linkedin.com/in/vishesh-patel-05278822a/" target="_blank">
                Linkedin
              </a>
              </p>
              <p><Link to="/Con">Contact</Link></p>

            </div>

          </div>
        )}
      </div>

    </div>

  );
};
export default Navbar;
