import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./Components/navbar/Navbar";
import Header from "./Container/header/Header";
import About from "./Container/about/About";
import Portfolio from "./Pages/portfolio/Portfolio";
import Experience from "./Pages/experience/Experience";
import Contact from "./Pages/contact/Contact";

import './App.css';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
      <Route path='/' exact element={<><Header /> <About /></>} />
        <Route path='/Port'  element={<Portfolio />} />
        <Route path='/Exp'  element={<Experience />} />
        <Route path='/Con'  element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
