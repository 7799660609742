import React from 'react';
import './experience.css';

const Experience = () => (
	<div className='exp' >
		<h2 className='heading'>Work Experience</h2>

		<ul className="timeline">
			<li className='one'>
				<div className="timeline-badge">
					<a><i className="fa fa-circle" id=""></i></a>
				</div>
				<div className="timeline-panel">
					<div className="timeline-heading">
						<h4>
							Frontend Developer
							CRTD Technologies (Internship)</h4>
					</div>
					<div className="timeline-body">
						<ul>
							<li>Managed the team  Of  10+ developers and Worked on  2  live projects. </li>
							<li>Managed designing team and redesignd  company's website.
								Led Application design session with client teams on 3+ project to  develop functional and technical requirement.
							</li>

						</ul>
					</div>
					<div className="timeline-footer">
						<p className="text-right">AUG 2022 - SEPT 2022</p>
					</div>
				</div>
			</li>

			<li className="timeline-inverted two">
				<div className="timeline-badge">
					<a><i className="fa fa-circle invert" id=""></i></a>
				</div>
				<div className="timeline-panel">
					<div className="timeline-heading">
						<h4>UI DESIGNER CRTD Technologies (Internship)</h4>
					</div>
					<div className="timeline-body">
						<p>Managed designing team and redesigned  company's website.
							Led Application design session with client teams on 3+ project to  develop functional and technical requirement.
						</p>
					</div>
					<div className="timeline-footer">
						<p className="text-right">SEPT 2022 - OCT 2022</p>
					</div>
				</div>
			</li>

			<li className='three'>
				<div className="timeline-badge">
					<a><i className="fa fa-circle" id=""></i></a>
				</div>
				<div className="timeline-panel">
					<div className="timeline-heading">
						<h4>Frontend Developer FIXPOINT Official (Full Time)</h4>
					</div>
					<div className="timeline-body">
						<ul >
							<li>Redesigned , developed and deployed whole website .</li>

						</ul>
					</div>
					<div className="timeline-footer">
						<p className="text-right">JUNE 2022 - DEC 2022</p>
					</div>
				</div>
			</li>

			<li className="timeline-inverted four">
				<div className="timeline-badge">
					<a><i className="fa fa-circle invert" id=""></i></a>
				</div>
				<div className="timeline-panel">
					<div className="timeline-heading">
						<h4>UI DESIGNER Fixpoint Official(Full Time)</h4>
					</div>
					<div className="timeline-body">

						<ul >
							<li>I'm responsible for creative design thinking, prototyping , and also over saw the team coordination for smooth functioning  </li>
							<li>Designed 2 apps and 1 website from scratch.</li>
							<li>I have also shared some ideas that the also been implemented. Which will be live soon.</li>


						</ul>
					</div>
					<div className="timeline-footer">
						<p className="text-right">JAN 2023 - FEB 2023</p>
					</div>
				</div>
			</li>
			<li className='five'>
				<div className="timeline-badge">
					<a><i className="fa fa-circle" id=""></i></a>
				</div>
				<div className="timeline-panel">
					<div className="timeline-heading">
						<h4>FREELANCE (UI/UX)</h4>
					</div>
					<div className="timeline-body">
						<ul >
							<li>I have  Designed For  Education , Job , Service and  also for Automotive supply  management  .</li>

						</ul>
					</div>
					<div className="timeline-footer">
						<p className="text-right">DEC 2022 - PRESENT</p>
					</div>
				</div>
			</li>

			<li className="timeline-inverted six">
				<div className="timeline-badge">
					<a><i className="fa fa-circle invert" id=""></i></a>
				</div>
				<div className="timeline-panel">
					<div className="timeline-heading">
						<h4>Aimerse Technologies India Private Limited (Internship)</h4>
					</div>
					<div className="timeline-body">

						<ul >
							<li>User Experience (UX) </li>
							<li>User Interface Design</li>


						</ul>
					</div>
					<div className="timeline-footer">
						<p className="text-right">MAR 2023 - PRESENT</p>
					</div>
				</div>
			</li>

			<li className="clearfix no-float"></li>
		</ul>
	</div>
);

export default Experience;