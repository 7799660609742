import React from 'react';
import './portfolio.css';
import Web_design1 from  '../../Assests/Web_design (1).png';
import Web_design2 from  '../../Assests/Web_design (2).png';
import Web_design3 from  '../../Assests/Web_design (3).png';
import Web_design4 from  '../../Assests/Web_design (4).png';
import Web_design5 from  '../../Assests/Web_design (5).png';
import Web_design6 from  '../../Assests/Web_design (6).png';
import Dev1 from  '../../Assests/dev (1).png';
import Dev2 from  '../../Assests/dev (2).png';
import { FaLink } from 'react-icons/fa';
import Dev3 from  '../../Assests/dev (3).png';
import App_design1 from  '../../Assests/App__design (1).png';
import App_design2 from  '../../Assests/App__design (2).png';
import App_design3 from  '../../Assests/App__design (3).png';







const Portfolio = () => (
  <>
	<div className="portfolio">
  <div id="portfolio">
        <div class="container">
        <h2 className="title">Development</h2>
            <div class="row">
                <div class="portfolio-col">
                    <img src={Dev1}/>
                    <div class="layer">
                        <h3>EV Service website</h3>
                        <p>Multi Brand EV Service Simplified</p>
                        <a href="http://fixpoint.in/" target="_blank"><FaLink/></a>
                    </div>
                </div>
              
                <div class="portfolio-col">
                    <img src={Dev3}/>
                    <div class="layer">
                        <h3>AI Community Website</h3>
                        <p>Unleashing the power of AI to shape a smarter future.</p>
                        <a href="https://aiclub.pages.dev/" target="_blank"><FaLink/></a>
                    </div>
                </div>
                <div class="portfolio-col">
                    <img src={Dev2}/>
                    <div class="layer">
                        <h3>Education Website</h3>
                        <p>We Create Results So You Focus On The Process.</p>
                        <a href="https://www.growupp.in/" target="_blank"><FaLink/></a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
	<div className="portfolio">
  <div id="portfolio">
        <div class="container">
        <h2 className="title">Web Design</h2>
            <div class="row">
                <div class="portfolio-col">
                    <img src={ Web_design1}/>
                    <div class="layer">
                        <h3>Events-Website-Design</h3>
                        <p>Best Amazing Event Happening In Your City</p>
                        <a href="https://www.behance.net/gallery/166072225/Events-Website" target="_blank"><FaLink/></a>
                    </div>
                </div>
              
                <div class="portfolio-col">
                    <img src={ Web_design2}/>
                    <div class="layer">
                        <h3>Jewel - Website-Design</h3>
                        <p>Beautiful, masterful design never goes out of fashion.</p>
                        <a href="https://www.behance.net/gallery/165717159/Jewel-Website" target="_blank"><FaLink/></a>
                    </div>
                </div>
                <div class="portfolio-col">
                    <img src={ Web_design3}/>
                    <div class="layer">
                        <h3>Beauty-salon-Design</h3>
                        <p>"Revitalize, rejuvenate, and radiate naturally."</p>
                        <a href="https://www.behance.net/gallery/166875055/Beauty-salon" target="_blank"><FaLink/></a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="portfolio-col">
                    <img src={ Web_design4}/>
                    <div class="layer">
                        <h3>Crypto-NFT-Marketplace-UI-Product-Design</h3>
                        <p>Discover, collect, and own digital art.</p>
                        <a href="https://www.behance.net/gallery/167351511/Crypto-NFT-Marketplace-UI-Product-Design" target="_blank"><FaLink/></a>
                    </div>
                </div>
              
                <div class="portfolio-col">
                    <img src={ Web_design5}/>
                    <div class="layer">
                        <h3>Sports-Website-Design</h3>
                        <p>Best Ecommerce Platform </p>
                        <a href="https://www.behance.net/gallery/166196537/Sports-Website" target="_blank"><FaLink/></a>
                    </div>
                </div>
                <div class="portfolio-col">
                    <img src={ Web_design6}/>
                    <div class="layer">
                        <h3>Courier-Website-Design</h3>
                        <p>Largest and reliable courier service </p>
                        <a href="https://www.behance.net/gallery/166304697/Courier-Website" target="_blank"><FaLink/></a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
<div className="portfolio">
  <div id="portfolio">
        <div class="container">
        <h2 className="title">App Design</h2>
            <div class="row">
                <div class="portfolio-col">
                    <img src={App_design2}/>
                    <div class="layer">
                        <h3>Shopping App Design</h3>
                        <p>Concept Design of Shopping App</p>
                        <a href="https://www.behance.net/gallery/167119255/Dog-App-Case-Study" target="_blank"><FaLink/></a>
                    </div>
                </div>
              
                <div class="portfolio-col">
                    <img src={App_design1}/>
                    <div class="layer">
                        <h3>Pawpeeps App Design</h3>
                        <p>App For New Dog Owner</p>
                        <a href="https://www.behance.net/gallery/165301799/Shopping-App" target="_blank"><FaLink/></a>
                    </div>
                </div>
                <div class="portfolio-col">
                    <img src={App_design3}/>
                    <div class="layer">
                        <h3>Music player App Design</h3>
                        <p>Concept Design of Music player App</p>
                        <a href="https://www.behance.net/gallery/162710863/Music-App-UI" target="_blank"><FaLink/></a>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>
</>
);

export default Portfolio;